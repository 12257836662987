import { render, staticRenderFns } from "./ActiveAllowances.vue?vue&type=template&id=1ec940c0&"
import script from "./ActiveAllowances.vue?vue&type=script&lang=js&"
export * from "./ActiveAllowances.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCardSubtitle,VCardTitle,VChip,VContainer,VDataTable,VDivider,VSpacer})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src3641046184/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1ec940c0')) {
      api.createRecord('1ec940c0', component.options)
    } else {
      api.reload('1ec940c0', component.options)
    }
    module.hot.accept("./ActiveAllowances.vue?vue&type=template&id=1ec940c0&", function () {
      api.rerender('1ec940c0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/items/ActiveAllowances.vue"
export default component.exports