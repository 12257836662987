<template>
  <v-card>
    <v-card-title style="padding-top: 2px" class="px-5 pb-0">
      Active Allowances - {{ item.description }}
      <v-spacer></v-spacer>
      <v-card-subtitle class="pa-y ma-0">
        Item ID: {{ item.item_id }}
      </v-card-subtitle>
    </v-card-title>
    <v-divider></v-divider>
    <v-container fluid class="pa-0">
      <v-data-table
        :headers="headers"
        :items="currentAllowances"
        dense
        fixed-header
        disable-pagination
        :footer-props="{'items-per-page-options': [-1]}"
        :items-per-page="-1"
        sort-by="status_constant">
        <template v-slot:[`item.status_constant`]="{ item }">
          <v-chip
            v-if="item && item.status_constant"
            small
            dark
            :color="item.statusColor">
            {{ item.statusText }}
          </v-chip>
        </template>
        <template v-slot:[`item.promo_name`]="{ item }">
          <span
            @click="$emit('goToPromo', item.promo_id)"
            class="primary--text"
            style="cursor: pointer">
            {{ item.promo_name }}
          </span>
        </template>
      </v-data-table>
    </v-container>
  </v-card>
</template>
<script>
export default {
  name: 'ActiveAllowances',
  data () {
    return {
      headers: [
        { text: 'Status', sortable: false, value: 'status_constant', align: 'center', class: 'accent white--text' },
        { text: 'Promo Name', sortable: false, value: 'promo_name', class: 'accent white--text', width: '15%' },
        { text: 'Promo Type', sortable: false, value: 'promo_type', class: 'accent white--text' },
        { text: 'Start Date', sortable: false, value: 'start_dt', class: 'accent white--text'},
        { text: 'End Date', sortable: false, value: 'end_dt', class: 'accent white--text'},
        { text: 'AMAP', sortable: false, value: 'amap_allowance', class: 'accent white--text' },
        { text: 'Ad BB', sortable: false, value: 'ad_billback', class: 'accent white--text' },
        { text: 'EDLC BB', sortable: false, value: 'edlc_billback', class: 'accent white--text' },
        { text: 'TPR BB', sortable: false, value: 'tpr_billback', class: 'accent white--text' },
        { text: 'Ad Scan', sortable: false, value: 'ad_scan', class: 'accent white--text' },
        { text: 'EDLC Scan', sortable: false, value: 'edlc_scan', class: 'accent white--text' },
        { text: 'TPR Scan', sortable: false, value: 'tpr_scan', class: 'accent white--text' }
      ],
      allowanceFields: [
        'ad_scan', 'edlc_scan', 'tpr_scan',
        'billback_allowance', 'amap_allowance', 'ad_billback', 'edlc_billback', 'tpr_billback'
      ]
    }
  },
  props: {
    item: Object,
    allowances: Array
  },
  computed: {
    currentAllowances () {
      if (!this.allowances || this.allowances.length === 0) return []
      return this.allowances.map(a => this.formatAllowance(a))
    }
  },
  methods: {
    formatAllowance (allowance) {
      for (const field of this.allowanceFields) {
        const value = allowance[field]
        allowance[field] = (value > 0) ? `$${this.$config.formatCurrency(value)}` : '-'
      }
      return {
        ...allowance,
        start_dt: this.$config.formatDate(allowance.start_dt),
        end_dt: this.$config.formatDate(allowance.end_dt),
        statusText: this.getStatusText(allowance.status_constant),
        statusColor: this.getStatusColor(allowance.status_constant)
      }
    },
    getStatusText (status_constant) {
      if (status_constant.toLowerCase() === 'pending_submission') {
        return 'PENDING'
      }
      return status_constant.replaceAll('_', ' ')
    },
    getStatusColor (status_constant) {
      switch (status_constant.toLowerCase()) {
        case 'pending_submission':
          return 'red'
        case 'submitted':
          return 'orange'
        case 'approved':
          return 'green'
        case 'rejected':
          return 'black'
        case 'changes_requested':
          return 'red'
        case 'cancelled':
          return 'grey lighten-1'
      }
    }
  }
}
</script>